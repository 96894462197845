import styled from 'styled-components'
import { Text } from '@shared/ui'
import { theme } from '@shared/config'

const ItemsContainer = styled.div`
  > :not(:last-child) {
    padding-bottom: ${theme.spacings.lg3}px;
  }

  @media (min-width: ${theme.breakpoints.md}px) {
    padding-left: ${theme.spacings.xl3}px;
  }
`

const Description = styled(Text)`
  max-width: 580px;
`

const DocumentsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: ${theme.spacings.sm}px;

  @media (min-width: ${theme.breakpoints.sm}px) {
    grid-template-columns: ${theme.documentSize.md}px ${theme.documentSize.md}px;
    column-gap: ${theme.spacings.sm}px;
  }
`

export const Styled = {
  ItemsContainer,
  Description,
  DocumentsContainer,
}

import styled from 'styled-components'
import { theme } from '@shared/config'

const DocumentsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: ${theme.spacings.sm}px;

  @media (min-width: ${theme.breakpoints.sm}px) {
    grid-template-columns: ${theme.documentSize.md}px ${theme.documentSize.md}px;
    column-gap: ${theme.spacings.sm}px;
  }
`

const DocumentItem = styled.div`
  display: flex;
  flex-direction: column;

  > :not(:last-child) {
    margin-bottom: ${theme.spacings.sm}px;
  }
`

const Container = styled.div`
  @media (min-width: ${theme.breakpoints.md}px) {
    padding-left: ${theme.spacings.xl3}px;
  }
`

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${theme.documentSize.md * 2 + theme.spacings.sm}px;
`

const MoreContainer = styled.div`
  padding-top: ${theme.spacings.md2}px;
`

export const Styled = {
  DocumentsContainer,
  DocumentItem,
  Container,
  BodyContainer,
  MoreContainer,
}

import styled from 'styled-components'
import { theme } from '@shared/config'

const Container = styled.div`
  display: grid;
  grid-template-columns: 100%;
  row-gap: ${theme.spacings.lg3}px;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    column-gap: ${theme.spacings.md5}px;
  }

  @media (min-width: ${theme.breakpoints.sm}px) {
    grid-template-columns: 1fr 380px;
    column-gap: ${theme.spacings.lg3}px;
  }
`

const ParagraphContainer = styled.div`
  > :not(:last-child) {
    padding-bottom: ${theme.spacings.sm}px;
  }

  @media (min-width: ${theme.breakpoints.md}px) {
    padding: 0 ${theme.spacings.lg0}px 0 ${theme.spacings.xl3}px;
  }
`
const DocumentsContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(min-content, ${theme.documentSize.md}px);
  row-gap: ${theme.spacings.xs3}px;
`

export const Styled = {
  Container,
  ParagraphContainer,
  DocumentsContainer,
}

import React, { FC } from 'react'
import { Document, Text } from '@shared/ui'
import { theme } from '@shared/config'
import { TLegalEntities } from './types'
import { Styled } from './styled'

export const LegalEntities: FC<TLegalEntities> = ({ title, items }) => (
  <>
    <Text {...theme.header}>{title}</Text>
    <Styled.ItemsContainer>
      {items.map(({ title, documents, description }) => (
        <div key={title}>
          <Text
            fontWeightMob={theme.fonts.fontWeights.bold}
            fontSizeMob={theme.fonts.fontSizes.md}
            marginBottomMob={theme.spacings.md2}
          >
            {title}
          </Text>
          <Styled.DocumentsContainer>
            {documents.map((document, index) => (
              <Document key={index} {...document} />
            ))}
          </Styled.DocumentsContainer>
          <Styled.Description
            {...theme.paragraph}
            lineHeightMob={theme.fonts.lineHeights.xs}
            marginTopMob={theme.spacings.md2}
          >
            {description}
          </Styled.Description>
        </div>
      ))}
    </Styled.ItemsContainer>
  </>
)

import React, { FC, useMemo, useState } from 'react'
import { MoreButton } from '@entities'
import { Document, Text } from '@shared/ui'
import { theme } from '@shared/config'
import { TForIndividuals } from './types'
import { Styled } from './styled'
import { MAX_DOCUMENTS_COUNT } from './config'

export const ForIndividuals: FC<TForIndividuals> = ({
  title,
  subTitle,
  documents,
  titleLoadingMore = 'Загрузить ещё',
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const isShowMore = useMemo(() => documents.length > MAX_DOCUMENTS_COUNT, [documents])
  const [evenColumns, oddColumns] = useMemo(() => {
    const data = isOpen ? documents : documents.slice(0, MAX_DOCUMENTS_COUNT)
    const result = [[], []]

    data.forEach((item, index) => result[index % 2].push(item))

    return result
  }, [documents, isOpen])

  return (
    <>
      <Text {...theme.header}>{title}</Text>
      <Styled.Container>
        <Text
          fontSizeMob={theme.fonts.fontSizes.md}
          fontWeightMob={theme.fonts.fontWeights.bold}
          marginBottomMob={theme.spacings.md2}
        >
          {subTitle}
        </Text>
        <Styled.BodyContainer>
          <Styled.DocumentsContainer>
            <Styled.DocumentItem>
              {evenColumns.map((document, index) => (
                <Document key={index} {...document} />
              ))}
            </Styled.DocumentItem>
            {Boolean(oddColumns.length) && (
              <Styled.DocumentItem>
                {oddColumns.map((document, index) => (
                  <Document key={index} {...document} />
                ))}
              </Styled.DocumentItem>
            )}
          </Styled.DocumentsContainer>
          {isShowMore && !isOpen && (
            <Styled.MoreContainer>
              <MoreButton onClick={() => setIsOpen(true)}>{titleLoadingMore}</MoreButton>
            </Styled.MoreContainer>
          )}
        </Styled.BodyContainer>
      </Styled.Container>
    </>
  )
}

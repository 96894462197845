import React, { FC, Fragment } from 'react'
import { Document, Text } from '@shared/ui'
import { theme } from '@shared/config'
import { TAboutBank } from './types'
import { Styled } from './styled'

export const AboutBank: FC<TAboutBank> = ({ title, paragraphs, branches, documents }) => (
  <Styled.Container>
    <div>
      <Text {...theme.header} marginBottomLg={theme.spacings.md4}>
        {title}
      </Text>
      <Styled.ParagraphContainer>
        {paragraphs.map((text, index) => (
          <Text key={index} {...theme.paragraph} lineHeightMob={theme.fonts.lineHeights.default}>
            {text}
          </Text>
        ))}
      </Styled.ParagraphContainer>
    </div>
    <div>
      <Text fontWeightMob={theme.fonts.fontWeights.bold} fontSizeMob={theme.fonts.fontSizes.md}>
        {branches.title}
      </Text>
      {branches.items.map(({ title, requisites, operatingMode }, index) => (
        <Fragment key={index}>
          <Text
            fontWeightMob={theme.fonts.fontWeights.bold600}
            fontSizeMob={theme.fonts.fontSizes.sm2}
            marginBottomMob={theme.spacings.xs3}
            marginTopMob={theme.spacings.md2}
          >
            {title}
          </Text>
          {requisites.map((text) => (
            <Text
              key={text}
              lineHeightMob={theme.fonts.lineHeights.default}
              fontSizeMob={theme.fonts.fontSizes.default}
            >
              {text}
            </Text>
          ))}
          {Boolean(operatingMode?.length) && (
            <>
              <Text
                fontWeightMob={theme.fonts.fontWeights.bold}
                fontSizeMob={theme.fonts.fontSizes.default}
                marginBottomMob={theme.spacings.xs3}
                marginTopMob={theme.spacings.xs3}
              >
                Режим работы
              </Text>
              {operatingMode.map((text) => (
                <Text
                  key={text}
                  lineHeightMob={theme.fonts.lineHeights.default}
                  fontSizeMob={theme.fonts.fontSizes.default}
                >
                  {text}
                </Text>
              ))}
            </>
          )}
        </Fragment>
      ))}
      <Text
        fontWeightMob={theme.fonts.fontWeights.bold}
        fontSizeMob={theme.fonts.fontSizes.md}
        marginBottomMob={theme.spacings.md2}
        marginTopMob={theme.spacings.lg3}
        marginTopTablet={theme.spacings.lg2}
        marginTopSm={theme.spacings.lg3}
      >
        {documents.title}
      </Text>
      <Styled.DocumentsContainer>
        {documents.items.map((item) => (
          <Document key={item.url} {...item} />
        ))}
      </Styled.DocumentsContainer>
    </div>
  </Styled.Container>
)

import { Dispatch, SetStateAction, useLayoutEffect, useState } from 'react'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import { useScrollElement } from '@shared/lib'

export const useActiveGroup = ({
  defaultActive,
}: {
  defaultActive: string
}): [string, Dispatch<SetStateAction<string>>] => {
  const searchParams = useSearchParams()
  const [active, setActive] = useState(() => defaultActive)
  const router = useRouter()
  const elementId = searchParams.get('group')

  useScrollElement({ elementId })

  useLayoutEffect(() => {
    if (elementId) {
      setActive(elementId)
      /** удаляем  group так как иначе при повторном клике ничего не произойдет и смены не будет */
      const { group, ...updatedQuery } = router.query

      if (group) {
        const newPathObject = {
          pathname: router.pathname,
          query: updatedQuery,
        }

        router.push(newPathObject, undefined, { shallow: true })
      }
    }
  }, [elementId, router])

  return [active, setActive]
}

import React, { FC, useCallback, useMemo, useState } from 'react'
import { MoreButton } from '@entities'
import { Document, Text } from '@shared/ui'
import { theme } from '@shared/config'
import { TInfoDisclosure } from '../types'
import { Styled } from '../styled'
import { DocumentGroup } from './DocumentGroup'
import { useActiveGroup } from '../lib/useActiveGroup'

const MAX_DOCUMENTS_COUNT = 10

export const InfoDisclosure: FC<TInfoDisclosure> = ({ title, docGroups, titleLoadingMore = 'Загрузить ещё' }) => {
  const [active, setActive] = useActiveGroup({ defaultActive: docGroups?.[0].alias })

  const [isOpen, setIsOpen] = useState(false)
  const isShowMore = useMemo(
    () => docGroups.find(({ alias }) => alias === active)?.documents?.length > MAX_DOCUMENTS_COUNT,
    [active, docGroups]
  )

  const [evenColumns, oddColumns] = useMemo(() => {
    const documents = docGroups.find(({ alias }) => alias === active)?.documents ?? []
    const data = isOpen ? documents : documents.slice(0, MAX_DOCUMENTS_COUNT)
    const result = [[], []]

    data.forEach((item, index) => result[index % 2].push(item))

    return result
  }, [active, isOpen, docGroups])

  const handleClickGroup = useCallback(
    (alias: string) => {
      setActive(alias)
      setIsOpen(false)
    },
    [setActive]
  )

  return (
    <>
      <Text {...theme.header} marginBottomMob={theme.spacings.md2}>
        {title}
      </Text>
      <Styled.Container>
        <Styled.LeftContainer>
          {docGroups.map(({ title, documents, alias }) => (
            <DocumentGroup
              alias={alias}
              title={title}
              key={alias}
              isActive={alias === active}
              onClick={() => handleClickGroup(alias)}
              documents={documents}
              titleLoadingMore={titleLoadingMore}
            />
          ))}
        </Styled.LeftContainer>
        <Styled.DocumentsContainer>
          <Styled.DocumentItem>
            {evenColumns.map((document, index) => (
              <Document key={index} {...document} />
            ))}
          </Styled.DocumentItem>
          {Boolean(oddColumns.length) && (
            <Styled.DocumentItem>
              {oddColumns.map((document, index) => (
                <Document key={index} {...document} />
              ))}
            </Styled.DocumentItem>
          )}
          {isShowMore && !isOpen && (
            <Styled.MoreContainer>
              <MoreButton onClick={() => setIsOpen(true)}>{titleLoadingMore}</MoreButton>
            </Styled.MoreContainer>
          )}
        </Styled.DocumentsContainer>
      </Styled.Container>
    </>
  )
}
